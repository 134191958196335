import { faker } from '@faker-js/faker';

export class BasicInformation {
    userFullName!: string;
    userPhone!: string;
    userEmail!: string;
    residenceCard!: string;
    mothersName!: string;
    fathersName!: string;
    gender!: string;
    nationality!: string;
    citizenship!: string;
    maidenName!: string;
    birthDate!: string;
    birthPlace!: string;
    city!: string;
    country!: string;
    address!: string;
    maritalStatus!: string;
    residenceCardCopy!: File[];
    note!: string;

    public constructor(init?: Partial<BasicInformation>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.userFullName) {
            errors.push("User full name is required");
        }

        if (!this.userPhone) {
            errors.push("User phone is required");
        }

        if (!this.userEmail) {
            errors.push("User email is required");
        }

        if (!this.residenceCard) {
            errors.push("Residence card is required");
        }

        if (!this.mothersName) {
            errors.push("Mother's name is required");
        }

        if (!this.fathersName) {
            errors.push("Father's name is required");
        }

        if (!this.gender) {
            errors.push("Gender is required");
        }

        if (!this.nationality) {
            errors.push("Nationality is required");
        }

        if (!this.citizenship) {
            errors.push("Citizenship is required");
        }

        if (!this.maidenName) {
            errors.push("Maiden name is required");
        }

        if (!this.birthDate) {
            errors.push("Birth date is required");
        }

        if (!this.birthPlace) {
            errors.push("Birth place is required");
        }

        if (!this.city) {
            errors.push("City is required");
        }

        if (!this.country) {
            errors.push("Country is required");
        }

        if (!this.address) {
            errors.push("Address is required");
        }

        if (!this.maritalStatus) {
            errors.push("Marital status is required");
        }

        return errors;
    }

    static randomBasicInfo(): BasicInformation {
        return new BasicInformation({
            userFullName: faker.name.fullName(),
            userPhone: faker.phone.number(),
            userEmail: faker.internet.email(),
            residenceCard: faker.random.alphaNumeric(10),
            mothersName: faker.name.firstName(),
            fathersName: faker.name.firstName(),
            gender: faker.name.gender(),
            nationality: faker.address.country(),
            citizenship: faker.address.country(),
            maidenName: faker.name.lastName(),
            birthDate: faker.date.past().toISOString().split('T')[0],
            birthPlace: faker.address.city(),
            city: faker.address.city(),
            country: faker.address.country(),
            address: faker.address.streetAddress(),
            maritalStatus: faker.helpers.arrayElement(['married', 'single']),
            residenceCardCopy: [],
            note: faker.lorem.sentence()
        });
    }

    toObject(): any {
        return {
            userFullName: this.userFullName.trim(),
            userPhone: this.userPhone.trim(),
            userEmail: this.userEmail.trim(),
            residenceCard: this.residenceCard.trim(),
            mothersName: this.mothersName.trim(),
            fathersName: this.fathersName.trim(),
            gender: this.gender.trim(),
            nationality: this.nationality.trim(),
            citizenship: this.citizenship.trim(),
            maidenName: this.maidenName.trim(),
            birthDate: this.birthDate.trim(),
            birthPlace: this.birthPlace.trim(),
            city: this.city.trim(),
            country: this.country.trim(),
            address: this.address.trim(),
            maritalStatus: this.maritalStatus.trim(),
            residenceCardCopy: this.residenceCardCopy,
            note: this.note.trim()
        }
    }
}