import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // i18n yapılandırmasını ekle
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useSessionStore } from './stores/sessionStore';
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Faqs from "./pages/Faqs";
import TrackApplication from "./pages/TrackApplication";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Appointment from "./pages/Appointment";
import Register from "./pages/Register";
import Services from "./pages/Services";
import Terms from './pages/Terms';
import FamilyVisa from './pages/sub-pages/FamilyVisa';
import WorkingVisa from './pages/sub-pages/WorkingVisa';
import CommercialVisa from './pages/sub-pages/CommercialVisa';
import TransitVisa from './pages/sub-pages/TransitVisa';
import TouristicVisa from './pages/sub-pages/TouristicVisa';
import GDPR from './pages/Gdpr';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import './bootstrap.min.css';
import './App.css';
import ActivationRequired from './pages/ActivationRequired';

const withAuth = (Component: React.ComponentType) => {
    const AuthComponent = (props: any) => {
        const sessionStore = useSessionStore();

        if (!sessionStore.hasSession()) {
            return <Navigate to="/login" />;
        }

        if (!sessionStore.isActive()) {
            return <Navigate to="/activation" />;
        }

        return <Component {...props} />;
    };

    return AuthComponent;
};

const ProtectedTrackApplication = withAuth(TrackApplication);
const ProtectedAppointment = withAuth(Appointment);


function App() {
   
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<Layout />}>
                    <Route index path='*' element={<Home />} />
                    <Route path='about-us' element={<About />} />
                    <Route path='faqs' element={<Faqs />} />
                    <Route path='contact' element={<Contact />} />
                    <Route path='login' element={<Login />} />
                    <Route path='register' element={<Register />} />
                    <Route path='services' element={<Services />} />
                    <Route path='terms' element={<Terms />} />
                    <Route path='aile-vizesi' element={<FamilyVisa />} />
                    <Route path='calisma-vizesi' element={<WorkingVisa />} />
                    <Route path='ticari-vize' element={<CommercialVisa />} />
                    <Route path='transit-vize' element={<TransitVisa />} />
                    <Route path='turistik-vize' element={<TouristicVisa />} />
                    <Route path="basvurular" element={<ProtectedTrackApplication />} />
                    <Route path="randevu" element={<ProtectedAppointment />} />
                    <Route path='activation' element={<ActivationRequired />} />
                    <Route path='404' element={<NotFound />} />
                    <Route path='gdpr' element={<GDPR />} />
                    <Route path='logout' element={<Logout />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
