import { faker } from '@faker-js/faker';

export class TouristVisaInformation {
    roundTripTicket!: string;
    travelHealthInsurance!: string;
    reasonForVisit!: string;
    invitationStatus!: string;
    inviterIsFullName!: string;
    inviterIdNumber!: string;
    hotelReservationStartDate!: string;
    hotelReservationEndDate!: string;
    hotelInfo!: string;
    travelVisaDuration!: string; //in days
    proofOfEmploymentOrStudentCer!: File[];
    proofOfIncome!: File[];
    hotelReservationOrInvitationCopy!: File[];
    inviterIdCopy!: File[];
    expiryDate!: File[];

    public constructor(init?: Partial<TouristVisaInformation>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.roundTripTicket) {
            errors.push("Round trip ticket is required");
        }

        if (!this.travelHealthInsurance) {
            errors.push("Travel health insurance is required");
        }

        if (!this.reasonForVisit) {
            errors.push("Reason for visit is required");
        }

        if (!this.invitationStatus) {
            errors.push("Invitation status is required");
        }

        if (!this.inviterIsFullName) {
            errors.push("Inviter's full name is required");
        }

        if (!this.inviterIdNumber) {
            errors.push("Inviter's ID number is required");
        }

        if (!this.hotelReservationStartDate) {
            errors.push("Hotel reservation start date is required");
        }

        if (!this.hotelReservationEndDate) {
            errors.push("Hotel reservation end date is required");
        }

        if (!this.hotelInfo) {
            errors.push("Hotel information is required");
        }

        if (!this.travelVisaDuration) {
            errors.push("Travel visa duration is required");
        }

        return errors;
    }

    static randomTouristVisaInfo(): TouristVisaInformation {
        return new TouristVisaInformation({
            roundTripTicket: faker.random.alphaNumeric(10),
            travelHealthInsurance: faker.random.alphaNumeric(10),
            reasonForVisit: faker.lorem.sentence(),
            invitationStatus: faker.random.alphaNumeric(10),
            inviterIsFullName: faker.name.findName(),
            inviterIdNumber: faker.random.alphaNumeric(10),
            hotelReservationStartDate: faker.date.future().toISOString().split('T')[0],
            hotelReservationEndDate: faker.date.future().toISOString().split('T')[0],
            hotelInfo: faker.address.streetAddress(),
            travelVisaDuration: faker.datatype.number({ min: 1, max: 90 }).toString(),
            proofOfEmploymentOrStudentCer: [],
            proofOfIncome: [],
            hotelReservationOrInvitationCopy: [],
            inviterIdCopy: [],
            expiryDate: []
        });
    }

    toObject(): any {
        return {
            roundTripTicket: this.roundTripTicket.trim(),
            travelHealthInsurance: this.travelHealthInsurance.trim(),
            reasonForVisit: this.reasonForVisit.trim(),
            invitationStatus: this.invitationStatus.trim(),
            inviterIsFullName: this.inviterIsFullName.trim(),
            inviterIdNumber: this.inviterIdNumber.trim(),
            hotelReservationStartDate: this.hotelReservationStartDate.trim(),
            hotelReservationEndDate: this.hotelReservationEndDate.trim(),
            hotelInfo: this.hotelInfo.trim(),
            travelVisaDuration: this.travelVisaDuration.trim(),
            proofOfEmploymentOrStudentCer: this.proofOfEmploymentOrStudentCer,
            proofOfIncome: this.proofOfIncome,
            hotelReservationOrInvitationCopy: this.hotelReservationOrInvitationCopy,
            inviterIdCopy: this.inviterIdCopy,
            expiryDate: this.expiryDate
        }
    }
}