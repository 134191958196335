import { faker } from '@faker-js/faker';

export class VisaInfo {
    visitReason!: string;
    stayAddress!: string;
    visaPeriodRequest!: number;
    visaNumber!: string;
    visaDate!: string;
    visaAddress!: string;
    appointmentDate!: string;
    appointmentTime!: string;
    oldVisaCopy!: File[];

    public constructor(init?: Partial<VisaInfo>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.visitReason) {
            errors.push("Visit reason is required");
        }

        if (!this.stayAddress) {
            errors.push("Stay address is required");
        }

        if (!this.visaPeriodRequest) {
            errors.push("Visa period request is required");
        }

        if (!this.visaNumber) {
            errors.push("Visa number is required");
        }

        if (!this.visaDate) {
            errors.push("Visa date is required");
        }

        if (!this.visaAddress) {
            errors.push("Visa address is required");
        }

        if (!this.appointmentDate) {
            errors.push("Appointment date is required");
        }

        if (!this.appointmentTime) {
            errors.push("Appointment time is required");
        }

        return errors;
    }

    static randomVisaInfo(): VisaInfo {
        return new VisaInfo({
            visitReason: faker.lorem.sentence(),
            stayAddress: faker.address.streetAddress(),
            visaPeriodRequest: faker.datatype.number({ min: 1, max: 12 }),
            visaNumber: faker.random.alphaNumeric(9),
            visaDate: faker.date.past(2).toISOString().split('T')[0],
            visaAddress: faker.address.streetAddress(),
            appointmentDate: faker.date.future().toISOString().split('T')[0],
            appointmentTime: faker.date.recent().toISOString().split('T')[1].substring(0, 5),
            oldVisaCopy: []
        });
    }

    toObject(): any {
        return {
            visitReason: this.visitReason.trim(),
            stayAddress: this.stayAddress.trim(),
            visaPeriodRequest: this.visaPeriodRequest,
            visaNumber: this.visaNumber.trim(),
            visaDate: this.visaDate.trim(),
            visaAddress: this.visaAddress.trim(),
            appointmentDate: this.appointmentDate.trim(),
            appointmentTime: this.appointmentTime.trim(),
            oldVisaCopy: this.oldVisaCopy
        }
    }
}