import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Form from '../components/Form';
import ContactSection from "../components/ContactSection";
import { UserService } from '../services/UserService';
import { useSessionStore } from '../stores/sessionStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthenticationService } from '../services/AuthenticationService';
import { useApplicationStore } from '../stores/applicationStore';
import { set } from 'lodash';
import moment from 'moment';
import _ from 'lodash';

const TrackApplication: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    const navigate = useNavigate()
    const userService = new UserService();
    const sessionStore = useSessionStore();
    const [user, setUser] = useState<any>(null)
    const applicationStore = useApplicationStore();
    const [applications, setApplications] = useState<any[]>([])

    useEffect(() => {
        userService.fetchAccount().then(user => {
            setUser(user)
        })
        console.log(sessionStore.me());
        applicationStore.fetch().then((data) => {
            setApplications(data)
            console.log(data);
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>Be Visa {t('applicationTracking')}</title>
                <meta name="keywords" content={`Be Visa ${t('applicationTracking')}`} />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('applicationTracking')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('applicationTracking')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <a href="/logout" title={t('logout')} className="button-default mb-4">{t('logout')}</a>
                            <h5>{t('processTracking')}</h5>
                            <h2>{t('yourApplications')}</h2>

                            <div className='table-responsive w-100'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{t('date')}</th>
                                            <th scope="col">{t('fullname')}</th>
                                            <th scope="col">{t('visaType')}</th>
                                            <th scope="col">{t('status')}</th>
                                            <th scope="col">{t('details')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            applications.map((application, index) => {
                                                return (
                                                    <tr key={_.uniqueId('application_')} className='align-middle'>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{moment(application.createdAt).format('DD.MM.YYYY')}</td>
                                                        <td>{application.basicInformation.userFullName}</td>
                                                        <td>{application.visaType}</td>
                                                        <td><span className={`badge text-bg-warning`}>{t(application.status)}</span></td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                                                                data-bs-target="#appDetailsModal">{t('details')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {/*
                                    <tr className='align-middle'>
                                        <th scope="row">1</th>
                                        <td>18.08.2024</td>
                                        <td>Ömer DALKIRAN</td>
                                        <td>Çalışma Vizesi</td>
                                        <td><span className="badge text-bg-success">{t('approved')}</span></td>
                                        <td>
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#appDetailsModal">{t('details')}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr className='align-middle'>
                                        <th scope="row">2</th>
                                        <td>18.08.2024</td>
                                        <td>Ömer DALKIRAN</td>
                                        <td>Çalışma Vizesi</td>
                                        <td><span className="badge text-bg-warning">{t('missingDoc')}</span></td>
                                        <td>
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#appDetailsModal">{t('details')}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr className='align-middle'>
                                        <th scope="row">3</th>
                                        <td>18.08.2024</td>
                                        <td>Ömer DALKIRAN</td>
                                        <td>Çalışma Vizesi</td>
                                        <td><span className="badge text-bg-danger">{t('rejected')}</span></td>
                                        <td>
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#appDetailsModal">{t('details')}
                                            </button>
                                        </td>
                                    </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="appDetailsModal" tabIndex={-1} aria-labelledby="appDetailsModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="appDetailsModalLabel">{t('applicationDetails')} </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='table-responsive w-100'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">{t('date')}</th>
                                                <th scope="col">{t('id')}</th>
                                                <th scope="col">{t('passport')}</th>
                                                <th scope="col">{t('accessInfo')}</th>
                                                <th scope="col">{t('visaHistory')}</th>
                                                <th scope="col">{t('AdditionalInfo')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='align-middle'>
                                                <th scope="row">18.08.2024</th>
                                                <td>Ömer DALKIRAN, Türk, Türkiye Cumhuriyeti, TC: 244822250, 10.08.1986,
                                                    Ahmet, Ayşe, Kadın, Evli
                                                </td>
                                                <td>Yeşil, PS02825, 19.08.2024, 12.08.202</td>
                                                <td>0533 368 15 35, 8 gün, Turizm, Ulukavak mh. Milönü Cd. No:8/2 Ankara
                                                </td>
                                                <td>0533 368 15 35, 8 gün, Turizm, Ulukavak mh. Milönü Cd. No:8/2 Ankara
                                                </td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                    aria-label="Close">{t('close')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section mb-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content-style-01'>
                                <h5>{t('haveAQuestion')}</h5>
                                <h2>{t('writeToUs')}</h2>
                            </div>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection />
        </>
    );
};

export default TrackApplication;
