import React, {ReactNode, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';

const Footer: React.FC = () => {
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş
    return (
        <>
            <footer>
                <a href='#home' className='logo text-center mx-auto d-block mb-5' title='Nova Crypto Marketing Agency'>
                    <img src={logo} title='Logo' alt='Nova Crypto Marketing Agency'></img></a>

                <div className="social-icons d-flex justify-content-center mb-4">
                    <a className="twitter" rel='noreferrer' title='Twitter' href="/static" target="_blank">
                        <i className="icon-social-twitter"></i>
                    </a>

                    <a className="telegram" rel='noreferrer' title='Telegram' href="/static" target="_blank">
                        <i className="bi bi-send"></i>
                    </a>
                    <a className="whatsapp" rel='noreferrer' title='Whatsapp' href="/static" target="_blank">
                        <i className="bi bi-whatsapp"></i>
                    </a>

                    <a className="facebook" rel='noreferrer' title='Facebook' href="/static" target="_blank">
                        <i className="icon-social-facebook"></i>
                    </a>
                    <a className="linkedin" rel='noreferrer' title='Linkedin' href="/static" target="_blank">
                        <i className="icon-social-linkedin"></i>
                    </a>
                    <a className="email" rel='noreferrer' title='E-Mail' href="mailto:info@bepartners.com"
                       target="_blank">
                        <i className="icon-envelope-open"></i>
                    </a>
                </div>

                <div className='col-lg-6 mx-auto'>
                    <nav id="main-nav" className="main-menu navbar navbar-expand-lg">

                        <div className="collapse navbar-collapse" id="navbarMain">


                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a href="/" className="nav-link">{t('home')}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/about-us" className="nav-link">{t('aboutUs')}</a>
                                </li>
                                <li className="nav-item d-none">
                                    <a href="/faqs" className="nav-link">{t('faqs')}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/contact" className="nav-link">{t('contact')}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/terms" className="nav-link">{t('terms')}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/gdpr" className="nav-link">{t('GDPR')}</a>
                                </li>
                            </ul>

                        </div>

                    </nav>

                </div>

                <div className='col-lg-8 mx-auto text-center'>
                    <p className='copyright'>{t('copyright')}</p>
                </div>
            </footer>
        </>
    )
}

export default Footer;
