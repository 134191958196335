import { faker } from '@faker-js/faker';

export class FamilyVisaInformation {
    visaSupporterClosenessDegree!: number;
    visaSupporterFullName!: string;
    supporterAlgIdNumber!: string;
    supporterNationality!: string;
    supporterBirthDate!: string;
    familyVisaDuration!: string;
    visaStartDate!: string;
    marriageCertificateCopy!: File[];
    civilRecords!: File[];
    birthCertificate!: File[];
    supporterPassportCopy!: File[];
    supporterIdCopy!: File[];
    familyInvitation!: File[];
    kinshipCertificate!: File[];

    public constructor(init?: Partial<FamilyVisaInformation>) {
        Object.assign(this, init);
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.visaSupporterClosenessDegree) {
            errors.push("Visa supporter closeness degree is required");
        }

        if (!this.visaSupporterFullName) {
            errors.push("Visa supporter full name is required");
        }

        if (!this.supporterAlgIdNumber) {
            errors.push("Supporter ALG ID number is required");
        }

        if (!this.supporterNationality) {
            errors.push("Supporter nationality is required");
        }

        if (!this.supporterBirthDate) {
            errors.push("Supporter birth date is required");
        }

        if (!this.familyVisaDuration) {
            errors.push("Family visa duration is required");
        }

        if (!this.visaStartDate) {
            errors.push("Visa start date is required");
        }

        return errors;
    }

    static randomFamilyVisaInfo(): FamilyVisaInformation {
        return new FamilyVisaInformation({
            visaSupporterClosenessDegree: faker.datatype.number({ min: 1, max: 10 }),
            visaSupporterFullName: faker.name.findName(),
            supporterAlgIdNumber: faker.random.alphaNumeric(10),
            supporterNationality: faker.address.country(),
            supporterBirthDate: faker.date.past(50).toISOString().split('T')[0],
            familyVisaDuration: faker.helpers.arrayElement(['1 month', '3 months', '6 months', '1 year']),
            visaStartDate: faker.date.future().toISOString().split('T')[0],
            marriageCertificateCopy: [],
            civilRecords: [],
            birthCertificate: [],
            supporterPassportCopy: [],
            supporterIdCopy: [],
            familyInvitation: [],
            kinshipCertificate: []
        });
    }

    toObject(): any {
        return {
            visaSupporterClosenessDegree: this.visaSupporterClosenessDegree,
            visaSupporterFullName: this.visaSupporterFullName.trim(),
            supporterAlgIdNumber: this.supporterAlgIdNumber.trim(),
            supporterNationality: this.supporterNationality.trim(),
            supporterBirthDate: this.supporterBirthDate.trim(),
            familyVisaDuration: this.familyVisaDuration.trim(),
            visaStartDate: this.visaStartDate.trim(),
            marriageCertificateCopy: this.marriageCertificateCopy,
            civilRecords: this.civilRecords,
            birthCertificate: this.birthCertificate,
            supporterPassportCopy: this.supporterPassportCopy,
            supporterIdCopy: this.supporterIdCopy,
            familyInvitation: this.familyInvitation,
            kinshipCertificate: this.kinshipCertificate
        }
    }
}