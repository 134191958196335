import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import moment from 'moment';
import { VisaInfo } from '../../models/VisaInfo';
import { AppointmentTime } from '../../models/enums/AppointmentTime';
import FileUploader from '../FileUploader';
import { VisaType } from '../../models/enums/VisaType';

interface VisaInfoFormProps {
    data: VisaInfo;
    onChange: (visaInfo: VisaInfo) => void;
}

const VisaInfoForm: React.FC<VisaInfoFormProps> = ({ data, onChange }) => {
    const { t } = useTranslation();

    const handleInputChange = (field: keyof VisaInfo) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
        if (field === 'visaDate' || field === 'appointmentDate') {
            onChange(new VisaInfo({
                ...data,
                [field]: moment(value).format('YYYY-MM-DD')
            }));
        } else {
            onChange(new VisaInfo({
                ...data,
                [field]: value
            }));
        }
    };

    const handleDrop = (field: keyof VisaInfo) => (acceptedFiles: File[]) => {
        onChange(new VisaInfo({
            ...data,
            [field]: acceptedFiles
        }));
    };

    return (
        <div className='form-box mb-4'>
            <h2><span className='step'></span>{t('visaFormPage.VisaInfo')}</h2>
            <div className='row'>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="visitReason" className="form-label">{t('visaFormPage.label23')}
                        <Tippy content={t('tooltip.visitReason')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>

                    <input type="text" className="form-control" id="visitReason" placeholder="" value={data.stayAddress} onChange={handleInputChange('visitReason')} />

                    {/* <select className='form-control' id="visitReason" value={data.visitReason} onChange={handleInputChange('visitReason')}>
                        <option value="">{t('visaFormPage.select')}</option>
                        {Object.entries(VisaType).map(([type, value]) => (
                            <option key={value} value={value}>{t(`${value}Visa`)}</option>
                        ))}
                    </select> */}
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="stayAddress" className="form-label">{t('visaFormPage.label24')}
                        <Tippy content={t('tooltip.stayAddress')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="stayAddress" placeholder="" value={data.stayAddress} onChange={handleInputChange('stayAddress')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="visaNumber" className="form-label">{t('visaFormPage.label25')}
                        <Tippy content={t('tooltip.visaNumber')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="number" className="form-control" id="visaNumber" placeholder="" value={data.visaNumber} onChange={handleInputChange('visaNumber')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="visaDate" className="form-label">{t('visaFormPage.label26')}
                        <Tippy content={t('tooltip.visaDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="date" className="form-control" id="visaDate" placeholder="" value={moment(data.visaDate).format('YYYY-MM-DD')} onChange={handleInputChange('visaDate')} />
                </div>
                <div className='col-lg-4 mb-3'>
                    <label htmlFor="visaAddress" className="form-label">{t('visaFormPage.label27')}
                        <Tippy content={t('tooltip.visaAddress')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <input type="text" className="form-control" id="visaAddress" placeholder="" value={data.visaAddress} onChange={handleInputChange('visaAddress')} />
                </div>

                <div className='col-lg-4 mb-3'>
                    <label htmlFor="appointmentDate" className="form-label">{t('visaFormPage.appointmentDate')}
                        <Tippy content={t('tooltip.appointmentDate')} placement="top">
                            <button className='form-tooltip'><i className="bi bi-question-circle-fill"></i></button>
                        </Tippy>
                    </label>
                    <div className='row'>
                        <div className='col-6'>
                            <input type="date" className="form-control" id="appointmentDate" placeholder="" value={moment(data.appointmentDate).format('YYYY-MM-DD')} onChange={handleInputChange('appointmentDate')} />
                        </div>
                        <div className='col-6'>
                            <select id='appointmentTime' className="form-select" aria-label="Default select appointmentTime" value={data.appointmentTime} onChange={handleInputChange('appointmentTime')}>
                                <option selected>{t('visaFormPage.select')}</option>
                                <option value="Time_09_10">09:00 - 10:00</option>
                                <option value="Time_10_11">10:00 - 11:00</option>
                                <option value="Time_11_12">11:00 - 12:00</option>
                                <option value="Time_12_13">12:00 - 13:00</option>
                                <option value="Time_13_14">13:00 - 14:00</option>
                                <option value="Time_14_15">14:00 - 15:00</option>
                                <option value="Time_15_16">15:00 - 16:00</option>
                                <option value="Time_16_17">16:00 - 17:00</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 mb-3'>
                    <label htmlFor="oldVisaCopy" className="form-label">{t('visaFormPage.oldVisaCopy')}</label>
                    <FileUploader onChange={handleDrop('oldVisaCopy')} label={t('visaFormPage.fileUploadTitle')} />
                </div>
            </div>
        </div>
    );
};

export default VisaInfoForm;