import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import banner2 from '../assets/banner-2.webp';
import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../services/AuthenticationService';
import { useSessionStore } from '../stores/sessionStore';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const Register: React.FC = () => {


    const navigate = useNavigate();
    const authService = new AuthenticationService();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const sessionStore = useSessionStore();
    const { t } = useTranslation(); // useTranslation hook'unu kullanarak 't' fonksiyonuna eriş

    return (
        <>
            <Helmet>
                <title>{t('register')}</title>
                <meta name="keywords" content="Kayıt Ol" />
            </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>{t('register')}</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('register')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='form-box mb-4'>
                                <h2>{t('register')}</h2>


                                <form className='user-form'>
                                    <div className='col-lg-12 mb-3'>
                                        <label htmlFor="email" className="form-label">{t('email')}</label>
                                        <input type="email" className="form-control" id="email" placeholder=""
                                            value={email}
                                            onChange={(event) => {
                                                setEmail(event?.target?.value)
                                            }}
                                        ></input>
                                    </div>
                                    <div className='col-lg-12 mb-3'>
                                        <label htmlFor="password" className="form-label">{t('password')}</label>
                                        <input type="password" className="form-control" id="password" placeholder=""
                                            value={password}
                                            onChange={(event) => {
                                                setPassword(event?.target?.value)
                                            }}
                                        ></input>
                                    </div>

                                    <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="userAgreement1" required></input>
  <label className="form-check-label" htmlFor="userAgreement1">
  {t('termsCheck01')}
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="userAgreement2" required></input>
  <label className="form-check-label" htmlFor="userAgreement2">
  {t('termsCheck02')} 
  </label>
</div>

                                    <a className='button-default mt-4 w-100' target='_blank' title={t('register')}
                                        onClick={() => {
                                            if (email.trim().length <= 0 || password.trim().length <= 0) {
                                                toast.warning('email or password can\'t be empty');
                                                return
                                            }
                                            authService
                                                .register(email.trim(), password.trim())
                                                .then(response => {
                                                    console.log(response)
                                                    if(_.isString(response)) {
                                                        toast.error(response)
                                                    }
                                                    if (response.access_token) {
                                                        setError('')
                                                        sessionStore.login(response);
                                                        setTimeout(() => {
                                                            navigate('/activation')
                                                        }, 200)
                                                    }
                                                    if (response.error) {
                                                        setError(response.error)
                                                    }
                                                })
                                        }}
                                    >
                                      {t('register')}
                                    </a>



                                    <a className='button-link mt-3 d-block' target='_blank' href='/reset-password'
                                        rel='noreferrer' title={t('forgotPassword')}>
                                    </a>
                                </form>
                            </div>
                        </div>
      
                    </div>
                </div>
            </section>
        </>
    );
};

export default Register;
