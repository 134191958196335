import { faker } from '@faker-js/faker';

export class BusinessVisaInformation {
    businessTurkishCompanyName!: string;
    businessTurkishCompanyAddress!: string;
    businessAlgeriaCompanyName!: string;
    businessAlgeriaCompanyAddress!: string;
    businessJob!: string;
    businessTitle!: string;
    businessVisaDuration!: string;
    businessVisaStartDate!: string;
    sgkServiceStatement!: File[];
    businessCompanyInvitation!: File[];
    businessUndertakingLetter!: File[];
    businessAlgerianCompanyDocs!: File[];
    businessTurkishCompanyDocs!: File[];

    public constructor(init?: Partial<BusinessVisaInformation>) {
        Object.assign(this, init);
    }


    validate(): string[] {
        const errors: string[] = [];

        if (!this.businessTurkishCompanyName) {
            errors.push("Business Turkish company name is required");
        }

        if (!this.businessTurkishCompanyAddress) {
            errors.push("Business Turkish company address is required");
        }

        if (!this.businessAlgeriaCompanyName) {
            errors.push("Business Algeria company name is required");
        }

        if (!this.businessAlgeriaCompanyAddress) {
            errors.push("Business Algeria company address is required");
        }

        if (!this.businessJob) {
            errors.push("Business job is required");
        }

        if (!this.businessTitle) {
            errors.push("Business title is required");
        }

        if (!this.businessVisaDuration) {
            errors.push("Business visa duration is required");
        }

        if (!this.businessVisaStartDate) {
            errors.push("Business visa start date is required");
        }

        return errors;
    }

    static randomBusinessVisaInfo(): BusinessVisaInformation {
        return new BusinessVisaInformation({
            businessTurkishCompanyName: faker.company.companyName(),
            businessTurkishCompanyAddress: faker.address.streetAddress(),
            businessAlgeriaCompanyName: faker.company.companyName(),
            businessAlgeriaCompanyAddress: faker.address.streetAddress(),
            businessJob: faker.name.jobTitle(),
            businessTitle: faker.name.jobType(),
            businessVisaDuration: faker.helpers.arrayElement(['1 month', '3 months', '6 months', '1 year']),
            businessVisaStartDate: faker.date.future().toISOString().split('T')[0],
            sgkServiceStatement: [], // empty array for simplicity
            businessCompanyInvitation: [], // empty array for simplicity
            businessUndertakingLetter: [], // empty array for simplicity
            businessAlgerianCompanyDocs: [], // empty array for simplicity
            businessTurkishCompanyDocs: [] // empty array for simplicity
        });
    }

    toObject(): any {
        return {
            businessTurkishCompanyName: this.businessTurkishCompanyName.trim(),
            businessTurkishCompanyAddress: this.businessTurkishCompanyAddress.trim(),
            businessAlgeriaCompanyName: this.businessAlgeriaCompanyName.trim(),
            businessAlgeriaCompanyAddress: this.businessAlgeriaCompanyAddress.trim(),
            businessJob: this.businessJob.trim(),
            businessTitle: this.businessTitle.trim(),
            businessVisaDuration: this.businessVisaDuration.trim(),
            businessVisaStartDate: this.businessVisaStartDate.trim(),
            sgkServiceStatement: this.sgkServiceStatement,
            businessCompanyInvitation: this.businessCompanyInvitation,
            businessUndertakingLetter: this.businessUndertakingLetter,
            businessAlgerianCompanyDocs: this.businessAlgerianCompanyDocs,
            businessTurkishCompanyDocs: this.businessTurkishCompanyDocs
        }
    }
}